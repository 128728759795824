<template>
  <v-container>
        <h1>The Kronk Report</h1>
        <v-card elevation="15" outlined>
          <p>
            Welcome to The Kronk Report; playing games every Tuesday since before the Pandemic.
          </p>
        </v-card>
        <v-card elevation="15">
          <h2>Current Games</h2>
          <v-list-item-group>
              <v-list-item><router-link to="/golfwithyourfriends">Golf With Your Friends</router-link></v-list-item>
          </v-list-item-group>
        </v-card>
    </v-container>
</template>

<script>
  export default {
    name: 'Home',

    data: () => ({
      courses: [
          {
              name: "Golf with Friends",
              header: "gwfHeader.png",
          },
      ],
    }),
  }
</script>
